import { AxiosError, AxiosResponse } from "axios";
import { serialize } from "cookie";
import { IncomingMessage, ServerResponse } from "http";
import parse from "set-cookie-parser";
import Axios from "./common.service";
import { Config } from "../utils/config";
import {
  ADD_CALL_REVIEW,
  GET_AUDIO_ROOM_DATA,
  GET_LIVE_ROOM_DATA,
  GET_REVIEW_ITEMS,
  GET_ROOM_DETAIL,
  GET_ROOM_FEED,
  GET_ROOM_ID_FEED,
  SET_ROOM_ID,
} from "../utils/apiEndPoints";
import {
  AuthReqParams,
  REQUEST_METHOD,
  ROOM_TYPE,
  RoomFeedResponse,
  RoomResponse,
} from "../types/common.types";
import {
  RoomReqBody,
  SetRoomReqBody,
} from "../modules/Astro/LiveSessions/rooms.types";
import { getAuthDataFromCookie, getCurrentPosition } from "../utils/helper";
import axiosInstance, { createInstance, setCookie } from "@/utils/customAxios";
import { CommonResponse } from "@/modules/Astro/Enum";
import {
  AddReviewReq,
  ReviewItemsBEResponse,
} from "@/common/components/ReviewPopup/ReviewPopup.types";

export const getRoomFeed = async (
  params: AuthReqParams,
  data: RoomReqBody,
  req?: IncomingMessage,
  res?: ServerResponse
) => {
  try {
    const response: RoomFeedResponse = { data: [], user: null };
    if (req) {
      // const authData = getAuthDataFromCookie(req);
      // if (authData?.token) {
      //   params.token = authData?.token;
      //   response.user = authData;
      // }
    }
    if (data?.category === "nearby" || data?.subCategory === "nearby") {
      try {
        const location: GeolocationPosition = await getCurrentPosition();
        data.location = [location.coords.latitude, location.coords.longitude];
      } catch (e) {
        console.log(e);
      }
    }

    const respData = await Axios<AxiosResponse<Array<RoomResponse>>>({
      method: REQUEST_METHOD.POST,
      url: `${Config.BACKEND_URL}${GET_ROOM_FEED}`,
      headers: {
        cookie: req?.headers?.cookie,
      },
      params,
      data,
    });

    if (res) {
      const setCookieHeader = respData?.headers?.["set-cookie"] || "";
      const cookies = parse(setCookieHeader);

      const serializedCookies = cookies.map(function (cookie) {
        return serialize(cookie.name, cookie.value, {
          ...cookie,
          sameSite: true,
        });
      });

      res?.setHeader("set-cookie", serializedCookies);
    }

    response.data = respData?.data?.data;

    return response;
  } catch (e) {
    console.error(e);
    console.log("error while fetching room feed");
    throw (e as AxiosError).response?.data;
  }
};

export const addReviewFeedback = async (
  token: string,
  reqBody: AddReviewReq
) => {
  try {
    const { data } = await axiosInstance<CommonResponse<string>>({
      method: REQUEST_METHOD.POST,
      url: ADD_CALL_REVIEW,
      params: { token },
      data: reqBody,
    });
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchReviewItems = async (token: string) => {
  try {
    const { data } = await axiosInstance<ReviewItemsBEResponse>({
      method: REQUEST_METHOD.GET,
      url: GET_REVIEW_ITEMS,
      params: { token },
    });

    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getRoomIdFeed = async (
  params: AuthReqParams,
  data: RoomReqBody,
  req?: IncomingMessage,
  res?: ServerResponse
) => {
  try {
    req && createInstance(req);
    const response: RoomFeedResponse = { data: [], user: null };
    if (req) {
      const authData = getAuthDataFromCookie(req);
      if (authData?.token) {
        params.token = authData?.token;
        response.user = authData;
      }
    }
    const respData = await axiosInstance<AxiosResponse<Array<RoomResponse>>>({
      method: REQUEST_METHOD.POST,
      url: GET_ROOM_ID_FEED,
      headers: {
        cookie: req?.headers?.cookie,
      },
      params,
      data,
    });

    if (res) {
      setCookie(respData, res);
    }

    response.data = respData?.data?.data;

    return response;
  } catch (e) {
    console.error(e);
    console.log("error while fetching room feed");
    throw (e as AxiosError).response?.data;
  }
};

export const setRoomId = async (data: SetRoomReqBody) => {
  try {
    const respData = await Axios<AxiosResponse>({
      method: REQUEST_METHOD.POST,
      url: SET_ROOM_ID,
      data,
    });
    return respData;
  } catch (e) {
    console.error(e);
    console.log("error while fetching room feed", e);
    throw (e as AxiosError).response?.data;
  }
};

export const fetchRoomData = async (roomId: string, reqBody: RoomReqBody) => {
  try {
    const { data } = await Axios<RoomResponse>({
      method: REQUEST_METHOD.POST,
      url: GET_ROOM_DETAIL,
      params: {
        roomId,
        ...reqBody,
        size: undefined,
        start: undefined,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

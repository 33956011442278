"use client";
import { Caller, ContentOwner } from "../utils/call-service.types";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import TRTC from "trtc-sdk-v5";
import {
  AuthType,
  ProfileDataInterface,
  ToastDataType,
} from "../utils/common.types";
import { Socket } from "socket.io-client";
import { AstroUserData, walletBalanceDataType } from "@/modules/Astro/Enum";
// import { WalletBalanceItem } from "@/modules/RoomContainer/types";
export interface PrivateCallContextData {
  channelId?: string;
  onCall?: boolean;
  callPopup?: boolean;
  buyPopup?: boolean;
  caller?: Caller;
  contentOwner?: ContentOwner;
  lowDiamondPopup?: boolean;
  duration?: number;
  reviewPopup?: boolean;
  purchasePlan?: string;
  topUpAmount?: number;
  insufficientBalancePopup?: boolean;
}

interface chatMemberAttributes {
  isOnline?: boolean;
  isTyping?: boolean;
}

export interface chatMemberProfile {
  userId?: string;
  name?: string;
  nickname?: string;
  gender?: "M" | "F";
  country?: string;
  profileType?: "CREATOR" | "OTHER";
  status?: string;
  photo?: string;
  creatorCategory?: string;
  creatorLevel?: number;
  earningsPerMinute?: number;
  earningsPerMinuteInInr?: number;
  earningsPerMinuteAudio?: number;
  earningsPerMinuteAudioInInr?: number;
  earningsPerMinuteChat?: number;
  earningsPerMinuteChatInInr?: number;
  pricePerMinute?: number;
  pricePerMinuteInInr?: number;
  pricePerMinuteAudio?: number;
  pricePerMinuteAudioInInr?: number;
  pricePerMinuteChat?: number;
  pricePerMinuteChatInInr?: number;
  rating?: {
    averageRating?: string;
    totalRatings?: number;
  };
  level?: {
    ONE?: number;
    TWO?: number;
    THREE?: number;
    FOUR?: number;
    FIVE?: number;
  };
  totalRatings?: number;
  audioCallFlag?: "GREYED_OUT" | "ENABLED";
  privateCallFlag?: "GREYED_OUT" | "ENABLED";
  paidDMFlag?: "GREYED_OUT" | "ENABLED";
  isEligibleForFreeCall?: boolean;
  permissions?: {
    isMarkedAsStreamerByAdmin?: boolean;
    receiveAudioCall?: boolean;
    receivePaidDM?: boolean;
    receivePrivateCall?: boolean;
  };
  languages?: string[];
  createdAt?: string;
  updatedAt?: string;
  profilePic?: string;
}

interface chatSession {
  beansPerMinute?: number;
  beansPerMinuteInInr?: number;
  currencyType?: number; // Assuming 1 represents a specific currency
  diamondsPerMinute?: number;
  diamondsPerMinuteInInr?: number;
  duration?: number; // In milliseconds
  freeDuration?: number; // In milliseconds
  offlineDurationMs?: number; // In milliseconds
  paymentType?: "PAID" | "FREE";
  roomId?: string;
  startTime?: number | null; // Unix timestamp in milliseconds
  timeLeftMs?: number;
  vCardFreeDurationMs?: number; // In milliseconds
}

export interface ChatContextData {
  initiator?: {
    attributes: chatMemberAttributes;
    user: chatMemberProfile;
  };
  messageHistory?: {
    messages?: [];
    totalMessages?: number;
    users?: [];
  };
  receiver?: {
    attributes: chatMemberAttributes;
    user: chatMemberProfile;
  };
  session?: chatSession;
  isChatPopup?: boolean;
  isInsufficientBalancePopup?: boolean;
  lowBalancePopup?: boolean;
  isReviewPopup?: boolean;
  duration?: number;
  onChat?: boolean;
  currencyType?: number;
}

export interface rawType {
  info: AstroUserData;
}

export interface ChatMsgContextData {
  message?: {
    attributes?: {
      isDelete?: boolean;
      isEdit?: boolean;
      isRead?: boolean;
      messageId?: string;
    };
    createdAt?: string;
    author: chatMemberProfile;
    data: {
      body?: string;
      raw?: rawType;
      urls: string[];
      systemType: string;
    };
    type?: string;
    messageId: string;
  };
  roomId?: string;
}

export interface GlobalPopups {
  showLoginPopup?: boolean;
  isProfilePopup?: boolean;
  downloadPopup?: boolean;
  logoutPopup?: boolean;
}

export interface AppContextProps {
  loader: boolean;
  setLoader: (val: boolean) => void;
  auth: AuthType;
  setAuth: (data: AuthType) => void;
  nonAuth: string;
  setNonAuth: React.Dispatch<React.SetStateAction<string>>;
  popups: GlobalPopups;
  setPopups: (data: GlobalPopups) => void;
  userData: ProfileDataInterface | null;
  setUserData: (data: ProfileDataInterface | null) => void;
  trtcClient: TRTC | null;
  setTrtcClient: (val: TRTC) => void;
  privateCallData: PrivateCallContextData;
  setPrivateCallData: (data: PrivateCallContextData) => void;
  chatData: ChatContextData;
  setChatData: React.Dispatch<React.SetStateAction<ChatContextData>>;
  chatMsgData: ChatMsgContextData;
  setChatMsgData: (data: ChatMsgContextData) => void;
  ToastData: ToastDataType;
  setToastData: React.Dispatch<React.SetStateAction<ToastDataType>>;
  // walletBalance: WalletBalanceItem;
  // setWalletBalance: React.Dispatch<React.SetStateAction<WalletBalanceItem>>;
  categoryHistory: string;
  setCategoryHistory: React.Dispatch<React.SetStateAction<string>>;
  privateCallSocket: Socket | null;
  setPrivateCallSocket: Dispatch<SetStateAction<Socket | null>>;
  paidDmSocket: Socket | null;
  setPaidDmSocket: Dispatch<SetStateAction<Socket | null>>;
  isCallScreen: boolean;
  setIsCallScreen: React.Dispatch<React.SetStateAction<boolean>>;
  chatIntakeData: AstroUserData | null;
  setChatIntakeData: React.Dispatch<React.SetStateAction<AstroUserData | null>>;
  walletBalance: walletBalanceDataType | null;
  setWalletBalance: React.Dispatch<
    React.SetStateAction<walletBalanceDataType | null>
  >;
}

const AppContext = createContext<AppContextProps | null>(null);

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [auth, setAuth] = useState<AuthType>({});
  const [nonAuth, setNonAuth] = useState<string>("");
  const [userData, setUserData] = useState<ProfileDataInterface | null>(null);
  const [trtcClient, setTrtcClient] = useState<TRTC | null>(null);
  const [popups, setPopups] = useState<GlobalPopups>({
    showLoginPopup: false,
    isProfilePopup: false,
    downloadPopup: false,
    logoutPopup: false,
  });

  const [walletBalance, setWalletBalance] =
    useState<walletBalanceDataType | null>(null);

  const [privateCallData, setPrivateCallData] =
    useState<PrivateCallContextData>({
      onCall: false,
      callPopup: false,
      buyPopup: false,
      insufficientBalancePopup: false,
      purchasePlan: "",
      topUpAmount: 0,
    });
  const [chatData, setChatData] = useState<ChatContextData>({});
  const [chatMsgData, setChatMsgData] = useState<ChatMsgContextData>({});
  const [chatIntakeData, setChatIntakeData] = useState<AstroUserData | null>(
    null
  );
  const [ToastData, setToastData] = useState<ToastDataType>({});
  // const [walletBalance, setWalletBalance] = useState<WalletBalanceItem>({});
  const [categoryHistory, setCategoryHistory] = useState<string>("");
  const [privateCallSocket, setPrivateCallSocket] = useState<Socket | null>(
    null
  );
  const [paidDmSocket, setPaidDmSocket] = useState<Socket | null>(null);
  const [isCallScreen, setIsCallScreen] = useState<boolean>(false);

  return (
    <AppContext.Provider
      value={{
        loader,
        setLoader,
        auth,
        setAuth,
        nonAuth,
        setNonAuth,
        popups,
        setPopups,
        userData,
        setUserData,
        trtcClient,
        setTrtcClient,
        privateCallData,
        setPrivateCallData,
        chatData,
        setChatData,
        chatMsgData,
        setChatMsgData,
        ToastData,
        setToastData,
        // walletBalance,
        // setWalletBalance,
        categoryHistory,
        setCategoryHistory,
        privateCallSocket,
        setPrivateCallSocket,
        paidDmSocket,
        setPaidDmSocket,
        isCallScreen,
        setIsCallScreen,
        chatIntakeData,
        setChatIntakeData,
        walletBalance,
        setWalletBalance,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
